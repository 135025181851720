<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon  @click="toBack" >
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
            <v-btn icon color="white">
              <v-icon>far fa-question-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <v-container>
      <!-- <v-row >
        <v-col cols="12" class="py-0">
          <div class="page-search">
            <v-text-field
              placeholder="Поиск"
              filled
              rounded
              dense
            ></v-text-field>
          </div>
        </v-col>
      </v-row> -->
      <v-row v-if="!loaded">
        <v-col class="p-0">
          <div >
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="loaded">
        <v-col cols=12 v-if="loaded == true && !appointments.length">
          <v-alert prominent text>
            Записи в истории отсутствуют
          </v-alert>
        </v-col>
      </v-row>     
      <v-row v-if="appointments">
        <v-col class="pt-0" cols="12"  :key="appointment.id" v-for="appointment in appointments">
          <v-card outlined>
              <v-list-item three-line>
                  <v-list-item-content class="pb-0">
                    <div class="d-flex justify-space-between">
                      <div>
                        <div class="overline">              
                          <v-chip x-small v-if="appointment.specialName">
                            {{ appointment.specialName }}
                          </v-chip>
                        </div>
                        <v-list-item-title class=" mb-1">
                          <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>{{ appointment.additionalAppointment ? $moment(appointment.dateFrom).format("LL, dddd") : $moment(appointment.dateFrom).format("LL, dddd, LT") }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-user-md</v-icon>{{ appointment.surname }} {{ appointment.name }} {{ appointment.middleName }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </v-list-item-content>
              </v-list-item>
              <v-card-actions class="pl-3 pb-3" >
                <v-btn class="text-decoration-none" 
                    :disabled="!appointment.idExamination" 
                    small 
                    text
                    link
                    :to="'/view-appointment?idExamination=' + appointment.idExamination"
                    color="primary" 
                    :dark="!!(appointment.idExamination)" 
                >Просмотреть</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { AppointmentsApiService, HistoryApiService } from '@/services/api'

export default {
    name: 'Appointments',
    data: function() {
        return {
            appointments: [],
            loaded: false,
            title: 'Мои записи',
        }
    },
    async mounted() {
        await this.getAppointments()
    },
    methods: {
        async getAppointments() {
            try {
                let appointments = await AppointmentsApiService.getAppointments()
                this.appointments = appointments
            } catch (err) {
                this.appointments = null
            } finally {
                this.loaded = true
            }
        },
        async getCoupons() {
            if (this.appointments.length != 0) {
                this.appointments.map(a => {
                HistoryApiService.getCoupons(a.id)
                    .then(resp => {
                    a.coupons = resp
                    })
                    .catch((a.coupons = []))
                })
            }
        }
    },
    computed: {

    }
}

</script>





<script>


import { HistoryApiService } from '@/services/api'

export default {
  name: 'History',

  data: function() {
    return {
      appointments: [],
      loaded: false,
      filterDoctor: '',
      filterSpecial: '',
    title: 'История посещений',
    }
  },
  async mounted() {
    try {
      let history = await HistoryApiService.getHistory()
      this.appointments = history
    } catch (err) {
      this.appointments = null
    } finally {
      this.loaded = true
    }
  },
   created: function() {
        // Событие отправки сообщения
        this.$eventBus.$emit('setTitle', this.title);
    },
        methods: {
        toBack() {
            this.$router.push('/')
        }
    },
  computed: {
  }
}
</script>


<style scoped>

.main-container{
    height: calc(100vh - 300px);
}

@media screen and  (max-width: 1200px){

    .main-container{
        height: calc(100vh - 220px);
    }

}

</style>