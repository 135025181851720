<template>
  <v-container>
    <v-row>
      <v-col
        md="10"
        offset-md="1"
        sm="10"
        offset-sm="1"
        xs="12"
        class="progress-bar-line"
        v-if="$mq === 'lg'"
      >
        <div 
          class="progress-item" 
          :class="[
            progress === 0 ? 'active' : '', 
            progress > 0 ? 'completed' : ''
            ]"
          key="0"
          v-if="this.getUser.representatives.length"
        >
          <i class="fas fa-check" v-if="progress>0"></i>
          укажите клиента
        </div>
        <div 
          class="progress-item" 
          :class="[
            progress === 1 ? 'active' : '', 
            progress > 1 ? 'completed' : ''
            ]"
          key="1"
        >
          <i class="fas fa-check" v-if="progress>1"></i>
          выберите услугу
        </div>
        <div 
          class="progress-item" 
          :class="[
            progress === 2 ? 'active' : '', 
            progress > 2 ? 'completed' : ''
            ]"
          key="2"
        >
          <i class="fas fa-check" v-if="progress>2"></i>
          выберите врача и время
        </div>
        <div 
          class="progress-item" 
          :class="[
            progress === 3 ? 'active' : '', 
            progress > 3 ? 'completed' : ''
            ]"
          key="3"
        >
          <i class="fas fa-check" v-if="progress>3"></i>
          подтвердите запись
        </div>
      </v-col>

      <v-col
        xs="12"
        class="progress-bar-line"
        v-if="$mq!=='lg'"
      >
        <div class="progress-bar-xs active">
          <span v-if="$mq === 'sm'">Запись</span>
          <span v-if="$mq !== 'sm'">Запись на прием</span>
        </div>
        <div class="progress-bar-xs" :class="[progress >= 1 ? 'active' : '']">
        </div>
        <div class="progress-bar-xs" :class="[progress >= 2 ? 'active' : '']">
        </div>
        <div class="progress-bar-xs" :class="[progress >= 3 ? 'active' : '']" style="text-align: right;">
          {{progress+1}}/4
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    progress: Number,
  },
  computed: {
    ...mapGetters(['getUser']),
  }
}

</script>

<style scoped>
  .progress-bar-line{
    display: flex;
    justify-content: space-between;
  }
  .progress-item{
    color: #D7DBDE;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .progress-item.active{
    color: rgb(31, 31, 31);
    border-bottom: 2px solid var(--primary-color);
  }
  .progress-item.completed{
    color: var(--primary-color);
  }

  .progress-bar-xs{
    width: 100%;
    border-bottom: 3px solid #D7DBDE;
  }
  .progress-bar-xs.active{
    border-bottom: 3px solid var(--primary-color);
  }
</style>
