<template>
 <div>
        <div class="header-mobile">
          <v-container>
            <div class="top-toolbar">
                <div class="navigate-btn" >
                    <v-btn icon  @click="toBack" >
                        <v-icon>fas fa-angle-left</v-icon>
                    </v-btn>
                </div>
                <div class="page-title">
                    {{title}}
                </div>
                <div class="second-btn">
                  
                </div>
            </div>
            </v-container>
        </div>
            <div class="container py-0">
                <div class="row">
                    <div class="col-md-12 py-1 pb-4">
                        <div class="service-type-section" >
                            <div class="card py-2 mb-2">
                                <div class="card-body py-2">
                                    <div class="iframe-wrap">
                                        <vue-friendly-iframe id="map_iframe" :src="STATIC.appMapIframe" ></vue-friendly-iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            title: 'Карта проезда',
            dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'STATIC'
        ])
    },
    methods: {
        toBack() {
            this.$router.push('/')
        }
    },
    created: function() {
        // Событие отправки сообщения
        this.$eventBus.$emit('setTitle', this.title);
    }
}

</script>

<style scoped>

    .content-heading {
        font-size: 30px;
    }

    .site-contact {
        height: 50vh;
    }

    .main-container{
        height: calc(100vh - 240px);
    }

    #map_iframe iframe{
       height: calc(100vh - 310px);
       width: 100%;
    }
    

    @media screen and  (max-width: 1200px){


    }

</style>

<style >

    #map_iframe iframe{
       height: calc(100vh - 350px);
            width: 100%;
    }

    @media screen and  (max-width: 1200px){

        #map_iframe iframe{
            height: calc(100vh - 320px);
                    width: 100%;
        }

    }
    

</style>

    

