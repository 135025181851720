import userGetters from './userGetters';
import chatGetters from './chatGetters';
import staticGetters from './staticGetters';
import appointmentGetters from './appointmentGetters';
import appointmentGettersNew from './appointmentGettersNew';
import reviewGetters from './reviewGetters';

export default  {
  networkError(state){
    return state.networkError
  },
  ...userGetters,
  ...chatGetters,
  ...staticGetters,
  ...appointmentGetters,
  ...appointmentGettersNew,
  ...reviewGetters
}