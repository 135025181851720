<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="toBack">
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn"></div>
        </div>
      </v-container>
    </div>
    <div>
      <v-container> 
        <transition name="router-anim">
          <router-view></router-view>
        </transition>
      </v-container>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      title: 'Записаться на прием'
    }
  },
  computed: {
    ...mapGetters([
      'getActiveUserName',
      'activeUser'
    ]),
  },
  methods: {
    // Вернутся назад
    toBack(){
      console.log(this.$router)
      this.$router.go(-1);
    },
    // Закрыть диалог создания записи по ссылке
    closeCreateDialog(){
      this.createLinkAppointmentDialog = false;
    }
  },
  created: function() {
    // Событие отправки сообщения
    this.$eventBus.$emit('setTitle', this.title);       
  },
  mounted: function() {

  }
}

</script>

<style scoped>

.router-anim-enter-active {
  animation: coming .4s;
  animation-delay: .6s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going .4s;
}

@keyframes going {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.date-section .v-picker{
  text-transform: capitalize;
}

.list-group-item{
  cursor: pointer;
}

.list-group-item-arrow{
  font-size: 16px;
  text-align: right;
}

.user-list-item-wrap{
  display: flex;
}

.user-list-info{
  padding: 0 15px;
  line-height: 20px;
}

.main-container{
  height: calc(100vh - 300px);
}

@media screen and  (max-width: 1200px){

  .main-container{
    height: calc(100vh - 220px);
  }

}

</style>