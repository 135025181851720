<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-none d-md-block">
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon disabled>
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            <span>{{title}}</span>
            <img src="@/assets/layout/logo.png" alt="">
          </div>
          <div class="second-btn"></div>
        </div>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary lighten-1" dark link to="/appointments" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Мои записи к врачу</v-card-title>
              <v-card-subtitle class="pr-0">Просмотр и редактирование ваших записей</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="far fa-calendar-check card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary darken-1" dark link to="/appointment" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Записаться на прием</v-card-title>
              <v-card-subtitle class="pr-0">Выбор врача и времени посещения клиники</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="far fa-calendar-plus card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      
      <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary" dark link to="/history" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">История посещений</v-card-title>
              <v-card-subtitle class="pr-0">Информация о визитах в медицинский центр</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-clipboard-list card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary darken-2" dark link to="/analyses" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Результаты исследований</v-card-title>
              <v-card-subtitle class="pr-0">Сведения о результатах лабораторных исследований</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-flask card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6" v-if="STATIC.appUseTelemedicine">
        <v-card color="primary" dark link to="/chat" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Телемедицина</v-card-title>
              <v-card-subtitle class="pr-0">Консультации с врачами онлайн</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="far fa-comment-dots card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6">
        <v-card color="primary lighten-1" dark link to="/information" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Профиль</v-card-title>
              <v-card-subtitle class="pr-0">Просмотр ваших данных</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-user-circle card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6" v-if="STATIC.appClinicUrl">
        <v-card color="primary darken-1" dark link :href="STATIC.appClinicUrl" target="_blank" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Медицинский центр</v-card-title>
              <v-card-subtitle class="pr-0">Перейти на сайт медицинского центра</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-globe card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6">
        <v-card color="primary darken" dark link to="/contact" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Контакты</v-card-title>
              <v-card-subtitle class="pr-0">Адрес и телефон регистратуры</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-hospital card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" md="6" lg="6">
        <v-card color="primary darken-1" dark link to="/map" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Как проехать</v-card-title>
              <v-card-subtitle class="pr-0">Схема проезда в медицинский центр</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="fas fa-map-marked-alt card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <!-- <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary darken-1" dark link to="/appointment-old" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Записаться на прием NEW</v-card-title>
              <v-card-subtitle class="pr-0">Выбор врача и времени посещения клиники</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="far fa-calendar-plus card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col> -->

      
    <v-dialog persistent v-model="createLinkAppointmentDialog" max-width="400">
      <v-card>
        <v-card-title>Создание записи</v-card-title>
        <v-card-text class="">
          <div v-if="this.loadingCreateAppointment" class="text-center mt-5">
            <v-progress-circular
              :size="50"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <p v-if="this.createLinkAppointmentErrorText">Не удалось создать запись. {{this.createLinkAppointmentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-if="!this.loadingCreateAppointment" @click="closeCreateDialog" rounded color="primary" dark>Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store'

export default {
    mounted() {
      this.$store.dispatch('setCurrentCouponService', null);

      // Если переданы параметры создания записи по ссылке
      if (localStorage.getItem('newAppointmentDate') && localStorage.getItem('newAppointmentIdUser')){
        this.createLinkAppointmentDialog = true;
        this.loadingCreateAppointment = true;
        this.$http.post(window.config.apiUrl + '/appointment/create-appointment', {
          idUser: localStorage.getItem('newAppointmentIdUser'),
          dateTime: localStorage.getItem('newAppointmentDate'),
          idService: localStorage.getItem('newAppointmentIdService'),
          idCustomer: this.$store.getters.activeUser,
          token: this.$store.getters.token,
        }).then((response) => {
          // Удаляем параметры
          localStorage.removeItem('newAppointmentDate');
          localStorage.removeItem('newAppointmentIdUser');
          localStorage.removeItem('newAppointmentIdService');
          if (response.data.status == 'success'){
            this.$router.push('/appointments')
          } else {
            this.loadingCreateAppointment = false;
            if (response.data.errorText){
              this.createLinkAppointmentErrorText = response.data.errorText
            } else {
              this.createLinkAppointmentErrorText = 'Ошибка создании записи'
            }
          }
        })
        .catch((error) => {
          // Удаляем параметры
          localStorage.removeItem('newAppointmentDate');
          localStorage.removeItem('newAppointmentIdUser');
          localStorage.removeItem('newAppointmentIdService');
          console.log(error);
          this.loadingCreateAppointment = false;
          this.createLinkAppointmentErrorText = true;
        });
      }
    },
    computed: {
      ...mapGetters([
        'STATIC'
      ]),
    },
    methods: {
      // Закрыть диалог создания записи по ссылке
      closeCreateDialog(){
        this.createLinkAppointmentDialog = false;
      }
    },
    data () {
        return {
            title: 'Начальная страница',
            createLinkAppointmentDialog: false, // Диалог создания записи по ссылке
            loadingCreateAppointment: false,
            createLinkAppointmentErrorText: '',
            // Создание записи по get запросу
            queryAppointment: {
              date: null,
              idUser: null
            },
        }
    },
}

</script>

<style scoped>

.card-icon {
    font-size: 80px;
}

.home-navigate-card:hover{
    text-decoration: none;
}

.no-wrap-title{
    word-break: break-word;
    line-height: 1.2;
    margin-bottom: 4px;
}

.page-title img{
    height: 40px;
    display: none;
}

</style>