<template>
    <div class="user-select-wrap" v-if="getUser.representatives.length">
        <div class="user-select-container" v-if="users.length">
            <div class="text-center">
                <h3 class="font-weight-light">Выберите пациента</h3>
                <h6 class="text-muted font-weight-light">Вы можете всегда изменить текущего пациента позже</h6>
            </div>
            <div class="user-slider-wrap">
                <div class="user-slider">
                    <carousel :data="users" :autoplay="false" indicator-type="disc"></carousel>
                </div>
            </div>
    
            <v-row>
                <v-col cols="12">
                    <v-btn dark :disabled="false" rounded color="primary" @click="selectUser" block>Выбрать</v-btn>
                </v-col>
                <v-col cols="12">
                   <v-btn class="text-decoration-none" text :disabled="false" rounded lint to="/" block>Пропустить</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            title: 'Выбор клиента'
        }
    },
    created(){
        if (!this.getUser.representatives.length) {
            this.$router.replace('/')
            this.$store.dispatch('selectUser', this.getUser.id)
        }
    },
    computed: {
        ...mapGetters([
            'getRepresentatives',
            'getUser'
        ]),
        users() {
            let sliderData = [];
            if (this.getRepresentatives){
                let users = [];
                // Добавляем основного пользователя
                users.unshift({
                    id: this.getUser.id,
                    name: this.getUser.name,
                    surname: this.getUser.surname,
                    middleName: this.getUser.middleName,
                    birthday: this.getUser.birthday,
                    gender: this.getUser.gender
                });

                // Добавляем представитесльство
                users.push(...this.getRepresentatives);
                // Формируем слайдер
                for (let user in users){
                    let currentUser = users[user];
                    let icon = '';
                    let birthday = this.$moment(currentUser.birthday);
                    let currDate = this.$moment();
                    let age = currDate.diff(birthday, 'years');

                    // Если указан пол
                    if (currentUser.gender){
                        // Если указан день рождения и пол
                        if (currentUser.birthday){
                            icon = 'fas fa-baby';
                            if (age > 3){
                                icon = 'fas fa-child';
                            }
                            if (age > 17){
                                if (currentUser.gender == 'Мужской'){
                                    icon = 'fas fa-male';
                                } else {
                                    icon = 'fas fa-female';
                                }
                            }
                        } else {
                            if (currentUser.gender == 'Мужской'){
                                icon = 'fas fa-male';
                            } else {
                                icon = 'fas fa-female';
                            }
                        }
                    } else {  // Если не указан пол
                        icon = 'fas fa-male';
                    }

                    sliderData.push(`
                        <div class="user-slide">
                            <div class="user-item" style="border-color: ${this.$vuetify.theme.themes.light.primary}" data-user-id="${currentUser.id}">
                                <i style="color: ${this.$vuetify.theme.themes.light.primary}"  class="${icon}"></i>
                                <div class="user-title font-weight-light">
                                ${currentUser.surname} ${currentUser.name} ${currentUser.middleName}
                                </div>
                            </div>
                        </div>
                    `);
                }
            }
            return sliderData;
        }
    },
    moutnted() {
       this.$store.dispatch('selectUser', this.$store.getters.getUser.id)
    },
    methods: {
        selectUser() {
            let idUser = document.querySelector('.carousel__item.carousel__item--active .user-item').getAttribute('data-user-id');
            this.$store.dispatch('selectUser', idUser);
            this.$router.push('/'); 
        }
    }
}

</script>

<style>

.user-slider{
    margin: auto;
    width: 90%;
       margin-top: 80px;
}

.user-slide{
    height: 220px;
    text-align: center;
 
}    

.user-slider-wrap{
    display: flex;
    flex-direction: column;

}

.user-item{
    background: #9797972b;
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 100%;
    border: 10px solid #1e88e5;
}

.carousel__indicator::before{
    background-color: #c1c1c1 !important;
}

.carousel__indicators--disc>.carousel__indicator {
    width: 1.75rem !important;
}

.user-select-wrap{
  background: #fff;
    /* position: absolute;
    top: 0;
    left: 0; */
    /* width: 100%;
    height: 100%; */
    /* z-index: 10; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 35px;
}

.user-select-container{
    max-width: 450px;
    margin: auto;
    width: 100%;
}

.user-item i {
    font-size: 90px;
    margin-top: 18px;
    color: #1e88e594;
    opacity: .6;
}

.user-title{
    margin-top: 40px;
    color: #6c757d;
    position: absolute;
    width: 100%;
    left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.carousel--controls:hover>.carousel__control {
    opacity: 0.3;
    transform: translateX(0);
    z-index: 1;
}

 @media screen and  (max-width: 1200px){

        .user-slider {
                margin-top: 15px;
        }

    }



</style>