<template>
  <v-app-bar app color="white" absolute flat class="header pt-5"> 
    <v-container>
      <v-row>
        <v-col cols="4">
          <router-link to="/">
            <img class="header-logo" :src="STATIC.appLogoUrl" alt=""/>
          </router-link>
        </v-col>
        <v-col cols="4" class="text-center d-none d-lg-flex" v-html="STATIC.appHeaderContent">
        </v-col>
        <v-col cols="4" class="text-right d-none d-lg-flex">
          <div style="width: 100%">
            <span class="customer-name">{{getActiveUserName}}</span>

            <v-btn to="/chat" active-class="no-active" v-if="this.UNREAD_COUNT" icon class="text-decoration-none"> 
              <v-badge :content="this.UNREAD_COUNT" :value="this.UNREAD_COUNT" color="light-green lighten-1" overlap depressed bordered>
                <v-icon>fas fa-comment-dots</v-icon>
              </v-badge>
            </v-btn>

            <v-menu bottom left offset-y nudge-bottom="15">
              <template v-slot:activator="{ on}">
                <v-btn icon x-large v-on="on">
                  <v-avatar>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="text-decoration-none" link to="/select-user" v-if="getRepresentatives">
                  <v-list-item-title>Выбрать пациента</v-list-item-title>
                </v-list-item>
                <v-list-item class="text-decoration-none" link to="/information">
                  <v-list-item-title>Профиль</v-list-item-title>
                </v-list-item>
                <v-list-item class="text-decoration-none" link to="/payment-process">
                  <v-list-item-title>Процесс оплаты</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="STATIC.appClinicUrl" class="text-decoration-none" link target="_blank" :href="STATIC.appClinicUrl">
                  <v-list-item-title>Сайт медицинского центра</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="STATIC.appPriceUrl" class="text-decoration-none" link target="_blank" :href="STATIC.appPriceUrl">
                  <v-list-item-title>Цены на услуги</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>Выйти</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'headerMenu',
    props: [
        'unreadCount'
    ],
    computed: {
      ...mapGetters([
        'getRepresentatives',
        'getActiveUserName',
        'activeUser',
        'STATIC',
        'UNREAD_COUNT'
      ]),
      userFullName() {
        const name = this.$store.getters.user.name
        const surname = this.$store.getters.user.surname
        const middleName = this.$store.getters.user.middleName
        return this.$store.getters.user.surname ? surname + ' ' + name + ' ' + middleName : '';
      }
    },
    mounted(){
     
    },
    methods: {
      logout: function () {
        localStorage.removeItem('login');
        localStorage.removeItem('password');
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        }).catch(err => console.log(err))
      }
    }
}
</script>

<style scoped>

.header{
  width: 100%
}

.customer-name{
    margin-right: 2px;
}

.header-logo{
    height: 50px;
    margin-top: 0px;
}

@media screen and  (max-width: 1200px){

    .header-logo {
      margin-left: -14px;
    }

}

</style>
