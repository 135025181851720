<template>
  <div>
    <v-card 
      elevation="2" 
      class="appointmet-info-wrapper mb-2"
      max-width="700"
    >
      <div class="appointmet-info" :class="[min ? 'min' : '']">
        <div class="wrap date">
          <i class="icon far fa-calendar-alt"></i>
          <span> Дата: <b>{{formatDate}}</b> </span>
        </div>
        <div class="wrap time" v-if="address">
          <i class="icon far fa-clock"></i>
          <span> Время: <b>{{time}}</b> </span>
        </div>
        <div class="wrap address">
          <i class="icon fas fa-map-marker-alt"></i>
          <span class="date"> Адрес: <b>{{address}}</b> </span>
        </div>
      </div>
    </v-card>

    <v-card 
      elevation="2" 
      class="appointmet-info-wrapper"
      max-width="700"
    >
      <div class="user-info">
        <div class="avatar">
          <v-img 
            max-height="150"
            max-width="150"
            min-height="150"
            min-width="150"
            :src="getSrc(user.id)"
            alt="Специалист"
          ></v-img>
        </div>
        <div class="description">
          <div v-if="shortName(user)">
            <h5>{{user.surname}} {{user.name}} {{user.middleName}}</h5>
          </div>
          <div v-else>
            <h6>{{user.surname}} {{user.name}} {{user.middleName}}</h6>
          </div>
          <div v-if="user.specials">
            <span v-for="(special, index) in user.specials" :key="index">
              {{special}}<span v-if="index!==user.specials.length-1">,</span> 
            </span>
          </div>
          <div v-if="user.userCategory">
            <span class="user-category">
              Категория - <b>{{user.userCategory}}</b>
            </span>
          </div>
          <div v-if="user.workExperience">
            <span class="user-exp">
              Стаж - <b>{{getExp(user.workExperience)}}</b>
            </span>
          </div>
          <div v-if="user.scientificAchievements">
            <span class="user-ach">
              <b>{{user.scientificAchievements}}</b>
            </span>
          </div>
        </div>
      </div>

      <div class="more-info pl10" @click="showInfo">
        <p v-if="hideInfo">
          Подробнее о специалисте 
          <i class="fas fa-angle-down"></i>
        </p>
        <p v-else>
          Скрыть описание 
          <i class="fas fa-angle-up"></i>
        </p>
        <span v-html="user.description" :class="[hideInfo ? 'd-none' : '']"></span>
      </div>

      <div class="services-select pl10" @click="showServices">
        <p v-if="service">
          {{service.nameService}}<br>
          <b>{{service.priceService / 100}} руб. </b>
          <i class="fas fa-angle-down" v-if="hideServices"></i>
          <i class="fas fa-angle-up" v-else></i>
        </p>
        <p v-else-if="hideServices">
          Выбрать услугу
          <i class="fas fa-angle-down"></i>
        </p>
        <p v-else>
          Скрыть услуги 
          <i class="fas fa-angle-up"></i>
        </p>

        <div class="services-list" :class="[hideServices ? 'd-none' : '']">
          <div class="services-list-item no-service"
            @click="serviceSelectHandel(null)"
          >
            Без услуги
          </div>
          <div 
            class="services-list-item" 
            v-for="service in user.services" 
            :key="service.id"
            @click="serviceSelectHandel(service)"
          >
            <span>{{service.nameService}}</span><br>
            <b>{{service.priceService / 100}} руб.</b>
          </div>
        </div>
      </div>

      <v-btn
        color="primary"
        block
        elevation="1"
        @click="createPayment(service)"
        :loading="loadingCreateAppointment"
      >
        <span v-if="!min && (!service || !service.requirePrepaymentInApp)">
          подтвердить запись к врачу {{formatDate}} в {{time}}
        </span> 
        <span v-else-if="min && (!service || !service.requirePrepaymentInApp)">
          записаться {{formatDate}} в {{time}}
        </span> 
        <span v-else-if="!min && service && service.requirePrepaymentInApp">
          оплатить оказание услуги {{formatDate}} в {{time}}
        </span> 
        <span v-else-if="min && service && service.requirePrepaymentInApp">
          оплатить
        </span>
      </v-btn>
    </v-card>

    <v-dialog persistent v-model="createLinkAppointmentDialog" max-width="400">
      <v-card>
        <v-card-title>Создание записи</v-card-title>
        <v-card-text class="">
          <div v-if="this.loadingCreateAppointment" class="text-center mt-5">
            <v-progress-circular
              :size="50"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <p v-if="this.createLinkAppointmentErrorText">{{this.createLinkAppointmentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-if="!this.loadingCreateAppointment" @click="closeCreateDialog" rounded color="primary" dark>Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import router from '../../router'

export default {
  data(){
    return {
      user: {},
      hideInfo: true,
      hideServices: true,
      service: '',
      createLinkAppointmentDialog: false, // Диалог создания записи по ссылке
      loadingCreateAppointment: false,
      createLinkAppointmentErrorText: '',
    }
  },
  props: {
    appToken: String,
  },
  created(){
    // получение данных о выбранном специалисте
    this.getData()
    // установка услуги, если есть
    if (this.serviceActive) this.service = this.serviceActive
  },
  computed: {
    ...mapGetters(['date', 'time', 'address', 'userId', 'serviceActive', 'token']),
    // форматирование даты для отображения
    formatDate(){
      return moment(this.date).locale('ru').format('Do MMM')
    },
    min(){
      if (this.$mq === 'sm') return true
      return false
    }
  },
  methods: {
    // получение данных о выбранном специалисте
    async getData(){
      const dateTo = ''
      const showFreeIntervals = false
      const showUserServices = true
      await this.$store.dispatch('getUser', {dateTo, showFreeIntervals, showUserServices})
        .then(res => {
          this.user = res
          })
    },
    // изменение состояния отображения подробной информации о враче
    showInfo(){
      this.hideInfo = !this.hideInfo
    },
    // изменение состояния отображения услуг
    showServices(){
      if (this.appToken) return
      this.hideServices = !this.hideServices
    },
    // обработчик выбора услуги
    serviceSelectHandel(service){
      console.log('click');
      if (service) {
        const data = {name: service.nameService, id: service.id, price: service.priceService}
        this.$store.commit('setService', data)
        localStorage.service = JSON.stringify(data)
        this.service = service
      } else {
        this.$store.commit('setService', null)
        localStorage.service = null
        this.service = null
      }
    },
    // получение стажа работы врача
    getExp(date){
      const years =  moment(date, "YYYYMMDD").locale('ru').fromNow();
      return years.split(' ').splice(0,2).join(' ')
    },
    createAppointment(){
      if (this.userId && this.date && this.time) {

        this.loadingCreateAppointment = true

        this.$http.post(window.config.apiUrl + '/appointment/create-appointment', {
          idUser: this.userId,
          dateTime: this.date + ' ' + this.time,
          idService: (this.serviceActive ? (this.serviceActive.id) : ''),
          idCustomer: this.$store.getters.activeUser,
          token: this.appToken || this.token,
        })
        .then((response) => {
          if (response.data.status == 'success'){
            if (!this.appToken){
              this.$router.push('/appointments');
            } else {
              this.loadingCreateAppointment = false
              this.createLinkAppointmentErrorText = 'Запись успешно создана'
              this.createLinkAppointmentDialog = true
            }
          } else {
            this.loadingCreateAppointment = false
            this.createLinkAppointmentDialog = true
            if (response.data.errorText){
              this.createLinkAppointmentErrorText = response.data.errorText
            } else {
              this.createLinkAppointmentErrorText = 'Ошибка создании записи'
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingCreateAppointment = false
          this.createLinkAppointmentDialog = true
          this.createLinkAppointmentErrorText = 'Ошибка создании записи'
        });
      }
    },
    // Закрыть диалог создания записи по ссылке
    closeCreateDialog(){
      this.createLinkAppointmentDialog = false
    },
    getSrc(userId){
      return (window.config.apiUrl + '/file/get-user-photo?idUser=' + userId)
    },
    shortName(user){
      return (
        user.surname?.length < 13 
        && user.name?.length < 13 
        && user.middleName?.length < 13
        )
    },
    // Создать оплату
    createPayment(service){
      if (this.userId && this.date && this.time) {

        if (this.appToken || !this.service) {
          this.createAppointment()
          return
        } else {

          this.loadingCreateAppointment = true
          let paymentStatusUrl = '';
          if (window.indexPlatform == 'ios'){
            paymentStatusUrl = window.config.url + '/cordova-ios.html'
          }
          if (window.indexPlatform == 'android'){
            paymentStatusUrl = window.config.url + '/cordova-android.html'
          }
          if (window.indexPlatform == 'browser'){
            paymentStatusUrl = window.config.url + '/index.html'
          }
          
          // Создаем счет
          this.$http.post(window.config.apiUrl + '/payments/create-payment', {
            idService: service.id,
            idUser: this.userId,
            idCustomer: this.$store.getters.activeUser,
            dateFrom: this.date + ' ' + this.time,
            idPayer: this.$store.getters.getUser.id,
            paymentStatusUrl: paymentStatusUrl
          })
          .then((response) => {
            // Если нет ошибок
            if (response.data.result.status == 'success'){
              console.log(response.data.result.acquiring);
              // Если услуга требует предоплату
              if (response.data.result.requirePrepaymentInApp){
                // Если форма оплаты получена
                if (response.data.result.acquiring && response.data.result.acquiring.formUrl){
                  // Перенаправляем на форму оплаты
                  window.location = response.data.result.acquiring.formUrl;
                } else {
                  // Запись создана но банк не предоставиль номер заказа
                  this.$router.push('/appointments');
                }
              } 
              else {
                // Если услуга не требуети предоплату
                this.loadingCreateAppointment = false
                this.$router.push('/appointments');
              }
            } 
            else {
              console.log(response.data);
              this.createLinkAppointmentErrorText = response.data.result.error;
              this.createLinkAppointmentDialog = true;
              // Скрываем загрузку услуги
              this.loadingCreateAppointment = false
            }
          })
          .catch((error) => {
            console.log(error);
            this.paymentErrorText = 'Ошибка при создании счета, попробуйте снова';
            this.errorPaymentDialog = true;
          });
        }
      }
    },
  },
}
</script>

<style scoped>
  .appointmet-info-wrapper{
    margin: 0 auto;
    padding: 10px;
  }
  .appointmet-info{
    display: flex;
    justify-content: space-between;
  }
  .appointmet-info.min{
    display: block;
  }
  .appointmet-info .wrap{
    padding: 10px;
  }
  .appointmet-info .icon{
    margin-right: 5px;
    color: #ccc;
  }
  .user-info{
    display: flex;
  }
  .user-info .description{
    padding: 5px;
  }
  .user-info .description span{
    font-size: 00.9rem;
  }
  .avatar{
    padding: 10px;
  }
  .more-info{
    cursor: pointer;
  }
  .more-info p{
    font-size: 0.8rem;
    color: rgb(134, 134, 134);
  }
  .services-select{
    cursor: pointer;
    font-size: 0.9rem;
  }
  .services-list :last-child{
    border: none;
  }
  .services-list-item{
    padding: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem;
  }
  .services-list-item:hover{
    color: var(--primary-color);
  }
  .services-list-item.no-service{
    color: #ccc;
  }
  .pl10{
    padding-left: 10px;
  }
</style>