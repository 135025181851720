<template>
  <div>
    <ProgressBar :progress="0"/>
    <div class="choice-title">
      <h5>Кто идет на прием?</h5>
    </div>

    <v-card
    elevation="2"
    class="mx-auto client-choice"
    max-width="344"
    outlined
    @click="clickHandler(userAge(getUser.birthday), getUser.id)"
    >
      <div>{{userName(getUser)}}</div>
      <div class="card-avatar" v-html="getIcon(getUser)"></div>
    </v-card>

    <v-card
    v-for="user in getUser.representatives"
    :key="user.id"
    elevation="2"
    class="mx-auto client-choice"
    max-width="344"
    outlined
    @click="clickHandler(userAge(user.birthday), user.id)"
    >
      <div>{{userName(user)}}</div>
      <div class="card-avatar" v-html="getIcon(user)"></div>
    </v-card>
  </div>
</template>

<script>

import router from '../../router'
import moment from 'moment'

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: "ClientSelect",
  components: {
    ProgressBar,
  },
  created(){
    if (!this.getUser.representatives.length) {
      this.$store.commit('setAge', this.userAge(this.getUser.birthday))
      this.$store.dispatch('selectUser', this.getUser.id)
      router.replace('/direction')
    }
  },
  methods: {
    // обработка выбора возраста клиента
    clickHandler(age, id){
      if (age === 0) {
        this.$store.commit('setAge', 1)
        localStorage.age = 1
      } else {
        this.$store.commit('setAge', age)
        localStorage.age = age
      }
      this.$store.dispatch('selectUser', id)
      // редирект на выбор направления
      router.push('/direction')
    },
    userName(user){
      return user.surname + ' ' + 
        user.name + ' ' + 
        (user.middleName ? user.middleName : '') 
    },
    userAge(bd){
      return moment().diff(bd, 'years');
    },
    getIcon(currentUser){
      let icon
      const age = this.userAge(currentUser.birthday)
      if (currentUser.gender){
        // Если указан день рождения и пол
        if (currentUser.birthday){
            icon = 'fas fa-baby';
            if (age > 3){
                icon = 'fas fa-child';
            }
            if (age > 17){
                if (currentUser.gender == 'Мужской'){
                    icon = 'fas fa-male';
                } else {
                    icon = 'fas fa-female';
                }
            }
        } else {
            if (currentUser.gender == 'Мужской'){
                icon = 'fas fa-male';
            } else {
                icon = 'fas fa-female';
            }
        }
      } else {  // Если не указан пол
          icon = 'fas fa-male';
      }
      return `<i class="${icon} fa-2x"></i>`
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  }


}
</script>

<style scoped>
  .client-choice{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
  }
  .card-avatar{
    display: flex;
    justify-content: center;
    width: 10%;
    color: var(--primary-color);
  }
  .choice-title{
    text-align: center;
    margin: 1rem 0;
  }
</style>