<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="toBack">
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
            <v-btn icon  v-if="false" @click="toTest">
              <v-icon>far fa-question-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <div>
      <v-container>
        <v-row>
          <v-col>
            <v-alert
              icon="mdi-account-circle"
              prominent
              text
              color="grey darken-1"
              class="px-5"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h6>{{getUser.surname}} {{getUser.name}} {{getUser.middleName}}</h6>
                  <h6>{{birthdatFormatDate(getUser.birthday)}}</h6>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col class="pt-0" cols="12" :key="representative.id" v-for="representative in representatives">
            <v-card outlined >
              <v-list-item three-line>
                <v-list-item-content class="">
                  <div class="d-flex justify-space-between">
                    <div>
                      <div class="overline ">              
                        <v-chip x-small>
                          Представительство
                        </v-chip>
                      </div>
                      <v-list-item-title class=" mb-1">
                        {{ representative.surname }} {{ representative.name }} {{ representative.middleName }} 
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ birthdatFormatDate(representative.birthday) }}
                      </v-list-item-subtitle>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Information',
  data: function() {
    return {
        representatives: [],
        title: 'Просмотр профиля'
    }
  },
  methods: {
    toBack() {
      this.$router.push('/')
    },
    toTest() {
      this.$router.push('/peer')
    },
    // Вернуть отформатированную дату записи
      birthdatFormatDate(dateFrom){
          return this.$moment(dateFrom).format("YYYY.MM.DD");
      },
  },
  async mounted() {
      this.representatives = this.getRepresentatives
  },
  computed: {
      ...mapGetters([
          'getRepresentatives',
          'getUser'
      ])
  }
}
</script>

<style scoped>

@media screen and  (max-width: 1200px){

}

</style>