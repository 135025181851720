<template>
  <div>
    <v-container> 
      <v-row v-if="!isLoadedServices">
        <v-col class="p-0">
          <div >
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isLoadedServices">
        <v-col class="py-0" cols="12">
          <div v-if="!showOnlineWidgetSelector && !showOnlineChatSelector && !showOnlineVideoSelector && !loadingServices">   
            <v-alert color="grey lighten-2" border="left" prominent>
              Услуги предоставляемые врачом не найдены
            </v-alert>
          </div>
          <div class="card py-2 mb-2">
            <div class="card-body py-2">
              <p class="text-muted mb-0">{{getActiveUserName}}</p>
              <div class="mb-0">
                <v-icon small>fas fa-user-md</v-icon> <small>{{ this.selectedUser.surname}} {{ this.selectedUser.name}} {{ this.selectedUser.middleName}} ({{this.selectedUser.special}})</small><br>
                <v-icon small>far fa-clock</v-icon> <small>{{getSelectedFormatDateTime}}</small><br>
                <div v-if="selectedAppointmentFilial" class="text-muted">
                  <small>{{getFilialAddress()}}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="card py-2 mb-2" v-if="showOnlineWidgetSelector">
            <div class="card-body py-2">
              <div>Запись на прием в клинику</div>
              <h5>{{getSelectedFormatDateTime}}</h5>
              <v-btn 
                small 
                :loading="loadingCreateAppointment" 
                rounded 
                color="primary" 
                :dark="!disabledButtons"
                :disabled="disabledButtons"
                @click.stop="createAppointment()"
              >
                Записаться на прием
              </v-btn>
            </div>
          </div>

          <div class="card py-2 mb-2" v-for="service in services" :key="service.id" >
            <div class="card-body py-2">
              <div>{{service.nameService}}</div>
              <small v-if="service.messageToCustomerOnMakingAppointment">
                <div v-if="service.messageToCustomerOnMakingAppointment.length <= 100">
                  <v-icon x-small>fas fa-info-circle</v-icon> {{service.messageToCustomerOnMakingAppointment}}
                </div>
                <div v-if="service.messageToCustomerOnMakingAppointment.length > 100 && !service.showInfo" >
                  <v-icon x-small>fas fa-info-circle</v-icon> {{service.messageToCustomerOnMakingAppointment.substring(0, 100)}}...<br>
                  <a href="#" @click.prevent="turnServiceInfo(service.id)">Показать полностью...</a>
                </div>
                <div v-if="service.showInfo">
                  <v-icon x-small>fas fa-info-circle</v-icon> {{service.messageToCustomerOnMakingAppointment}}...<br>
                  <a href="#" @click.prevent="turnServiceInfo(service.id)">Скрыть описание...</a>
                </div>
              </small>
              <v-chip class="my-2 mr-2" color="grey lighten-2" text-color="grey" small v-if="service.onlineChat">
                <v-avatar left>
                  <v-icon small>fas fa-comment</v-icon>
                </v-avatar>
                Чат
              </v-chip>
              <v-chip class="my-2" color="grey lighten-2" text-color="grey" small v-if="service.onlineVideo">
                <v-avatar left>
                  <v-icon small>fas fa-video</v-icon>
                </v-avatar>
                Видео
              </v-chip>
              <h5>{{service.priceService}}</h5>
              <v-btn 
                v-if="service.requirePrepaymentInApp"
                :dark="!disabledButtons"
                small 
                rounded 
                :disabled="disabledButtons"
                color="primary" 
                :loading="service.loading"
                @click="createPayment(service)"
              >
                Перейти к оплате
              </v-btn>
              <v-btn 
                v-if="!service.requirePrepaymentInApp"
                :dark="!disabledButtons"
                small 
                rounded 
                :disabled="disabledButtons"
                color="primary" 
                :loading="service.loading"
                @click="createPayment(service)"
              >
                Записаться на прием
              </v-btn>
            </div>
          </div>

          <v-dialog v-model="createAppointtmentDialog" persistent max-width="400">
            <v-card>
              <v-card-title>Вы записались</v-card-title>
              <v-card-text>
                <div class="overline">              
                  <v-chip x-small>
                    {{this.selectedUser.special}}
                  </v-chip>
                </div>
                <v-list-item-title class=" mb-1">
                  {{getSelectedFormatDateTime}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{getActiveUserName}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="this.createdAppointmentServiceName">
                  {{this.createdAppointmentServiceName}}
                </v-list-item-subtitle>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="toAppointments" text rounded color="primary" dark>Мои записи</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="errorAppointmentDialog" max-width="400">
            <v-card>
              <v-card-title>Запись на прием</v-card-title>
              <v-card-text>
                <p>Не удалось создать запись. {{this.appointmentErrorText}}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded text color="primary" dark @click="hideAppointmentError">Закрыть</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="errorPaymentDialog" max-width="400">
            <v-card>
              <v-card-title>Оплата услуги</v-card-title>
              <v-card-text>
                <p>Не удалось выполнить оплату услуги. {{this.paymentErrorText}}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded color="primary" dark @click="hidePaymentError">Закрыть</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
         
        </v-col>
      </v-row>
    </v-container> 
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      filials: [],
      disabledButtons: false,
      isLoadedServices: false,
      loadingCreateAppointment: false,
      createAppointtmentDialog: false,
      services: [],
      showOnlineVideoSelector: false,
      showOnlineChatSelector: false,
      showOnlineWidgetSelector: false,
      selectedServiceType: false,
      errorAppointmentDialog: false,
      appointmentErrorText: '',
      selectedAppointmentFilial: null,
      paymentErrorText: '',
      errorPaymentDialog: false,
      createdAppointmentServiceName: null
    }
  },
  methods: {
    ...mapActions([
      'SELECT_SPECIAL',
      'SELECT_SERVICE_TYPE'
    ]),
    // Получить филиалы записи
    getFilials(){
      this.$http.get(window.config.apiUrl + '/filials',)
        .then((response) => {
          if (response.data){
            this.filials = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFilialName(){
      let filial = this.filials.filter(filial => filial.id == this.selectedAppointmentFilial);
      return filial.length ?  filial[0].name : '';
    },
    // Скрыть ошибку о неудачной оплаты счета
    hidePaymentError(){
      this.errorPaymentDialog = false;
    },
    getFilialAddress(){
      let filial = this.filials.filter(filial => filial.id == this.selectedAppointmentFilial);
      return filial.length ?  filial[0].address : '';
    },
    toAppointments(){
      this.$router.push('/appointments');
    },
    hideAppointmentError(){
      this.errorAppointmentDialog = false;
    },
    selectServiceType(serviceType){
      this.SELECT_SERVICE_TYPE(serviceType);
      this.$router.push('/appointment/select-service');
    },
    // Переключить показ описания услуги
    turnServiceInfo(serviceId){
      this.services.forEach(service => {
        if (service.id == serviceId){
          this.$set(service, 'showInfo', !service.showInfo)
        }
      })
    },
    // Записатся на прием
    createAppointment() {
      this.disabledButtons = true;
      this.createdAppointmentServiceName = false;
      this.loadingCreateAppointment = true;
      this.$http.post(window.config.apiUrl + '/appointment/create-appointment', {
        idUser: this.selectedUser.id,
        dateTime: this.selectedAppointment.date + ' ' + this.selectedAppointment.timeFrom,
        idCustomer: this.$store.getters.activeUser,
      }).then((response) => {
        if (response.data.status == 'success'){
          this.createAppointtmentDialog = true;
        } else {
          this.errorAppointmentDialog = true;
          this.appointmentErrorText = response.data.errorText
        }
        this.loadingCreateAppointment = false;
        this.disabledButtons = false;
        this.services.forEach(serviceItem => {
          this.$set(serviceItem, 'loading', false)
        })
      })
      .catch((error) => {
        console.log(error);
        this.loadingCreateAppointment = false;
        this.errorAppointmentDialog = true;
        console.log('Error catch')
        this.disabledButtons = false;
        this.services.forEach(serviceItem => {
          this.$set(serviceItem, 'loading', false)
        })
      });
    },  
    selectSpecial(idSpecial){
      this.SELECT_SPECIAL(idSpecial);
      this.$router.push('/appointment/select-user');
    },
    // Создать оплату
    createPayment(service){
      this.disabledButtons = true;
      service.loading = true;
      // Подготавливаем адрес возврата после оплаты
      let paymentStatusUrl = '';
      if (window.indexPlatform == 'ios'){
        paymentStatusUrl = window.config.url + '/cordova-ios.html'
      }
      if (window.indexPlatform == 'android'){
        paymentStatusUrl = window.config.url + '/cordova-android.html'
      }
      if (window.indexPlatform == 'browser'){
        paymentStatusUrl = window.config.url + '/index.html'
      }
      
      // Создаем счет
      this.$http.post(window.config.apiUrl + '/payments/create-payment', {
        idService: service.id,
        idUser: this.selectedUser.id,
        idCustomer: this.$store.getters.activeUser,
        dateFrom: this.selectedAppointment.date + ' ' + this.selectedAppointment.timeFrom,
        idPayer: this.$store.getters.getUser.id,
        paymentStatusUrl: paymentStatusUrl
      })
      .then((response) => {
        // Если нет ошибок
        if (response.data.result.status == 'success'){
          console.log(response.data.result.acquiring);
          // Если услуга требует предоплату
          if (response.data.result.requirePrepaymentInApp){
            // Если форма оплаты получена
            if (response.data.result.acquiring && response.data.result.acquiring.formUrl){
              // Перенаправляем на форму оплаты
              window.location = response.data.result.acquiring.formUrl;
            } else {
              // Запись создана но банк не предоставиль номер заказа
              this.$router.push('/appointments');
            }
          } else {
            // Если услуга не требуети предоплату
            this.createAppointtmentDialog = true;
            this.loadingCreateAppointment = false;
            this.disabledButtons = false;
            this.services.forEach(serviceItem => {
              this.$set(serviceItem, 'loading', false)
            })
          }
        } else {
          console.log(response.data);
          this.paymentErrorText = response.data.result.error;
          this.errorPaymentDialog = true;
          // Скрываем загрузку услуги
          this.services.forEach(service => {
            this.$set(service, 'loading', false)
          })
          this.disabledButtons = false;
          let loadingService = this.services.filter(service => service.loading);
        }
      })
      .catch((error) => {
        console.log(error);
        this.paymentErrorText = '';
        this.errorPaymentDialog = true;
        this.disabledButtons = false;
        this.services.forEach(service => {
          this.$set(service, 'loading', false)
        })
      });
    },
  },
  computed: {
    ...mapGetters([
      'CREATE_APPOINTMENT',
      'getActiveUserName',
      'activeUser'
    ]),
    getSelectedFormatDateTime() {
      return this.$moment(this.selectedAppointment.date +  ' ' + this.selectedAppointment.timeFrom).format("LL, dddd, LT");
    },
  },
  created: function() {
      this.getFilials();
      if (!this.CREATE_APPOINTMENT.selectedAppointment){
        this.$router.push('/appointment');
        return false;
      }
      this.selectedUser = this.CREATE_APPOINTMENT.selectedUser;
      let appointment = this.CREATE_APPOINTMENT.selectedAppointment;
      this.currentStep = 'serviceType';
      this.selectedAppointment = appointment;
      this.selectedAppointmentFilial = appointment.idFilial;
      this.showOnlineChatSelector = false;
      this.showOnlineVideoSelector = false;
      this.showOnlineWidgetSelector = appointment.onlineWidget;
      this.selectedServiceType = false;
      let idSchedule = this.selectedAppointment.idSchedule;

      // Получаем список услуг
      this.$http.get(window.config.apiUrl + '/services/get-user-services-by-schedule-id?idSchedule=' + idSchedule)
        .then((response) => {
          this.services = response.data.services;
          this.services.forEach(service => {
            this.$set(service, 'showInfo', false);
            this.$set(service, 'loading', false)
          })
          for (let service in this.services){
            if (this.services[service].onlineChat){
              this.showOnlineChatSelector = true;
            }
            if (this.services[service].onlineVideo){
              this.showOnlineVideoSelector = true;
            }
          }
          this.isLoadedServices = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
}

</script>

<style scoped>

.list-group-item{
  cursor: pointer;
}

.list-group-item-arrow{
  font-size: 16px;
  text-align: right;
}

.main-container{
  height: calc(100vh - 300px);
}

@media screen and  (max-width: 1200px){

  .main-container{
      height: calc(100vh - 220px);
  }

}

</style>