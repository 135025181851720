export default {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, token){
    state.status = 'success'
    state.token = token
  },
  auth_error(state){
    state.status = 'error';
  },
  logout(state){
    state.status = '';
    state.token = '';
  },
  setSession(state, {user, token}) {
    state.user = user;
    state.token = token;
    if (!state.activeUser){
      state.activeUser = localStorage.getItem('activeUser')
    }
  },
  selectUser(state, idUser) {
    state.activeUser = idUser;
  },
  setRepresentativeCount(state, count){
    state.representativeCount = count;
  },   
}