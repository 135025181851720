<template>
  <div>
    <ProgressBar :progress="2"/>
    
    <v-container>
      <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <div  
            class="service-name"
          >{{input}}</div>
          <div class="service-label">
            Список врачей оказывающих услугу
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!loading">
      <UserCard v-for="user in users" :key="user.id" :user="user" :showIntervals="false" :link="'/new-appointment/user'"/>
    </div>
    <Skeleton v-if="loading"/>
    <div class="notify" v-else-if="emptyUsers">
      <i class="fas fa-exclamation-circle"></i>
      <div>для выбранной услуги нет подходящих врачей</div>
    </div>
  </div>
</template>

<script>

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import UserCard from '../../components/appointment/UserCard.vue'
import Skeleton from '../../components/appointment/Skeleton.vue'

import { mapGetters } from 'vuex'
import router from '../../router'

export default {
  name: "Users",
  data(){
    return {
      // отображение загрузки при получении данных
      loading: false,
      // модель для инпута
      input: '',
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeMount(){
    // Если переданы параметры при записи из рассылки
    if (this.$route.query.token && this.$route.query.customerId && this.$route.query.serviceId) {
      this.$store.dispatch('getServiceById', this.$route.query.serviceId)
      .then(() => {
        if (!this.service) {
          this.input = 'Услуга указана некорректно'
        }
        else{
          this.input = this.service?.name
          this.$store.commit('setAppointmentToken', this.$route.query.token)
          this.$store.commit('selectUser', this.$route.query.customerId)
          this.getData()
        }
      })
    }
    else{
      // перенаправление если не выбрана услуга
      if (!this.service) router.push('/services')
      this.input = this.service?.name
      // получение пользователей для текущей даты
      this.getData()
    }
  },
  components: {
    ProgressBar,
    UserCard,
    Skeleton,
  },
  methods: {
    // получение пользователей для текущей даты
    async getData(){
      // активирует загрузку
      this.loading = true
      // получение данных
      await this.$store.dispatch('getUsers', this.idSpecial)
      // скрыть загрузку
      this.loading = false
    },
  },
  computed: {
    ...mapGetters(['users', 'service', 'age']),
    // проверка, есть ли пользователи для текущей даты
    emptyUsers(){
      for (let key in this.users) {
        return false
      }
      return true
    },
  },
  watch: {
    idSpecial: function(){
      this.getData()
    }
  }

}
</script>

<style scoped>
.service-label{
    font-size: 0.8em;
    color: rgb(143, 143, 143);
  }
  .service-name{
    font-size: 0.9em;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .notify{
    color: #ccc;
    text-align: center;
  }
</style>