<template>
    <div class="footer-mobile">
         <v-navigation-drawer app v-model="drawer" right>
            <v-list nav>
                <v-list-item-group active-class="primary--text text--accent-4">
                    <v-list-item two-line to="/information"  class="text-decoration-none">
                        <v-list-item-avatar>
                            <img src="@/assets/layout/avatar.png" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{getActiveUserName}}</v-list-item-title>
                            <v-list-item-subtitle>Профиль</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="text-decoration-none" link to="/select-user" v-if="getRepresentatives">
                        <v-list-item-title>Выбрать пациента</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="text-decoration-none" link to="/payment-process">
                        <v-list-item-title>Процесс оплаты</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="logout()"> 
                        <v-list-item-title>
                            Выйти
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-bottom-navigation app temporary color="primary">
            <v-btn to="/" class="text-decoration-none">
                <v-icon>fas fa-home</v-icon>
            </v-btn>
            <v-btn to="/appointments" class="text-decoration-none">
                <v-icon>far fa-calendar-check</v-icon>
            </v-btn>
            <v-btn to="/appointment" class="text-decoration-none">
                <v-icon>far fa-calendar-plus</v-icon>
            </v-btn>
            <v-btn to="/chat" v-if="STATIC.appUseTelemedicine" @click="$store.dispatch('setCurrentCouponService', null);" class="text-decoration-none"> 
                <v-badge :content="this.UNREAD_COUNT" :value="this.UNREAD_COUNT" color="light-green lighten-1" overlap depressed bordered>
                    <v-icon>far fa-comment-dots</v-icon>
                </v-badge>
            </v-btn>
            <v-btn @click.stop="drawer = !drawer" >
                <v-icon>fas fa-user-circle</v-icon>      
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>

import eventBus from '../../eventBus';
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            drawer: false,
        }
    },
    computed: {
         ...mapGetters([
            'getRepresentatives',
            'getActiveUserName',
            'UNREAD_COUNT',
            'STATIC'
        ]),
        user() {
            return this.$store.getters.user
        }
    },
    methods: {
        logout: function() {
            localStorage.removeItem('login');
            localStorage.removeItem('password');
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push('/login')
            })
        },
        goToBack() {
            eventBus.$emit('goToBack');
        },
    }
}

</script>

<style scoped>

.footer-mobile{
    display: none;
}

@media screen and (max-width: 1200px){
    .footer-mobile{
        display: block;
    }
}

</style>


<style >

.footer-mobile{
    display: none;
}

@media screen and (max-width: 380px){
    .footer-mobile .v-item-group.v-bottom-navigation .v-btn {
        min-width: 70px;
    }
}

@media screen and (max-width: 360px){
    .footer-mobile .v-item-group.v-bottom-navigation .v-btn {
        min-width: 60px;
    }
}

</style>


