<template>
  <div>
    <ProgressBar :progress="3"/>
    <AppointmentCard :appToken="appointmentToken"/>
  </div>
</template>

<script>

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import AppointmentCard from '../../components/appointment/AppointmentCard.vue'
import router from '../../router'

import { mapGetters } from 'vuex'

export default {
  created(){
    // если нет данных о дате, времени и враче - редирект на выбор врача
    const shouldRedirect = !(this.date && this.time && this.userId)
    if (shouldRedirect) router.push('/users')
  },
  components: {
    ProgressBar,
    AppointmentCard,
  },
  computed: mapGetters(['date', 'time', 'userId', 'appointmentToken'])
}

</script>

<style>
  
</style>