<template>
  <div>
    <v-card class="review-item my-5" elevation="2">
      <div class="title-container">
        <v-icon v-if="type == 'question_with_comments'"  color="blue darken-2">mdi-message-text</v-icon>
        <v-icon v-if="type == 'question_yes_no'" color="yellow darken-2">mdi-help-circle-outline</v-icon>
        <v-icon v-if="type == 'question_with_rating'" color="orange darken-2">mdi-star-circle</v-icon>
        <v-icon v-if="type == 'question_with_dropdownmenu'"  color="green darken-2">mdi-playlist-check</v-icon>
        <div class="text--primary">
          {{title}}
        </div>
      </div>

      <v-container>

        <v-text-field
          v-if="type == 'question_with_comments'"
          @input="updateValue"
          label="Введите сообщение"
          outlined
          clearable
        ></v-text-field>

        <v-radio-group 
          v-if="type == 'question_yes_no'"
          class="radio-group" 
          row 
          @change="updateValue"
        >
          <v-radio label="Да" value="Да"></v-radio>
          <v-radio label="Нет" value="Нет"></v-radio>
        </v-radio-group>

        <v-select 
          v-if="type == 'question_with_dropdownmenu'"
          @change="updateValue" 
          :items="options" 
          label="Ваш вариант"
        >
        </v-select> 

        <div v-if="type == 'question_with_rating'">
          <v-rating
            v-model="rating"
            @input="updateValue"
            background-color="grey lighten-3"
            color="orange"
            :length="ratingCount"
            large
            clearable
          ></v-rating>
          <v-divider></v-divider>
          <div class="rating-text">
            {{ ratingToText }}
          </div>
        </div>

      </v-container>   
    </v-card>
    </div>
</template>

<script>

export default {
   props: {
    title: String,
    index: Number,
    type: String,
    ratingCount: Number,
    ratingText: Boolean,
    options: null,
  },
  data(){
    return{
      rating: null
    }
  },
  methods: {
    updateValue(value){
      const data = {
        index: this.index, 
        itemValue: value
      }
      console.log(data);
      this.$store.commit('changeTemplateItemValues', data)
    },
  },
  computed: {
    ratingToText(){
      const ratingStep = this.ratingCount / 4;
      if (this.rating){
        if (this.rating < ratingStep) return "Плохо"
        else if (this.rating < ratingStep * 2) return "Удовлетворительно"
        else if (this.rating < ratingStep * 4) return "Хорошо"
        else if (this.rating === this.ratingCount) return "Отлично"
        else return null
      }
      return null
    }
  }
}

</script>

<style scoped>

.review-item{
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.title-container{
  display: flex; 
  align-items: flex-start;
  padding: 0.5rem;
}

.title-container .text--primary{
  margin-left: 0.5rem;
}

</style>
