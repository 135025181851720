<template>
  <div>
    <v-container> 
      <!-- <v-row>
        <v-col cols="12" class="py-0">
          <div class="page-search">
            <v-text-field
              placeholder="Поиск"
              filled
              rounded
              dense
            ></v-text-field>
          </div>
        </v-col>
      </v-row> -->
      <v-row v-if="!isLoadedUsers">
        <v-col class="p-0">
          <div>
            <v-skeleton-loader class="mx-0 p-0" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mx-0 p-0" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isLoadedUsers">
        <v-col cols="12" v-if="!users.length">
          <v-alert prominent text>
            На данный момент нет свободных врачей.
          </v-alert>
        </v-col>
        <v-col class="py-0" cols=12 v-if="users.length">
          <v-row>
            <h5>Выберите сотрудника</h5>
            <v-container>
              <v-row dense>
                <v-col cols="12" v-for="user in users" :key="user.id">
                  <v-card @click="selectUser(user)" :elevation="0" outlined >
                    <v-list-item three-line>
                      <v-list-item-avatar size="80" :src="getUserPhoto(user.id)">
                        <v-img position="top" :src="getUserPhoto(user.id)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div v-if="user.onlineVideo || user.onlineChat">
                          <div class="overline">
                            <v-chip x-small>телемедицина</v-chip>
                          </div>
                          <div class="overline">
                            <v-icon v-if="user.onlineVideo" small color="grey lighten-1" class="mr-2">fas fa-video</v-icon>
                            <v-icon v-if="user.onlineChat" small color="grey lighten-1" class="mr-2">fas fa-comment</v-icon>
                          </div>
                        </div>
                        <v-list-item-title class="mb-1">{{user.surname}} {{user.name}} {{user.middleName}}</v-list-item-title>
                        <v-list-item-subtitle>{{user.special}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container> 
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      users: [],
      isLoadedUsers: false,
    }
  },
  computed: {
    ...mapGetters([
      'CREATE_APPOINTMENT'
    ]),
    filterServices() {
      return this.services.filter(service => (service.onlineChat && this.selectedServiceType == 'chat') || (service.onlineVideo && this.selectedServiceType == 'video'));
    },
    getSelectedFormatDateTime() {
      return this.$moment(this.selectedAppointment.date +  ' ' + this.selectedAppointment.timeFrom).format("LL, dddd, LT");
    },
  },
  methods: {
    ...mapActions([
      'SELECT_USER'
    ]),
    getUserPhoto(idUser){
      return window.config.apiUrl + '/file/get-user-photo?idUser=' + idUser
    },
    selectUser(idUser){
      this.SELECT_USER(idUser);
      this.$router.push('/appointment/select-date');
            
    },
  },
  created: function() {
    // Получить порльзователей
    let idSpecial = this.CREATE_APPOINTMENT.selectedSpecial;
    if (!idSpecial){
      this.$router.push('/appointment');
      return false;
    }
    this.$http.get(window.config.apiUrl + '/users?idSpecial=' + idSpecial+ '&idCustomer=' + this.$store.getters.activeUser)
      .then((response) => {
        if (response.data){
          this.users = response.data;
        }
        this.isLoadedUsers = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

</script>

<style scoped>

  .list-group-item{
    cursor: pointer;
  }

  .list-group-item-arrow{
    font-size: 16px;
    text-align: right;
  }

  .main-container{
    height: calc(100vh - 300px);
  }

  @media screen and  (max-width: 1200px){

    .main-container{
        height: calc(100vh - 220px);
    }

  }

</style>