export default{
  setAppointmentToken(state, data){
    state.createAppointmentNew.appointmentToken = data
  },
  setAddress(state, data){
    state.createAppointmentNew.address = data
  },
  setTime(state, data){
    state.createAppointmentNew.time = data
  },
  setDate(state, date){
    state.createAppointmentNew.date = date
  },
  setAdult(state, data){
    state.createAppointmentNew.adult = data
  },
  setWidth(state, data){
    state.createAppointmentNew.width = data
  },
  setUsers(state, data){
    state.createAppointmentNew.users = data
  },
  setUserId(state, data){
    state.createAppointmentNew.userId = data
  },
  setSpecialId(state, data){
    console.log(data);
    state.createAppointmentNew.idSpecial = data
  },
  setQ(state, data){
    state.createAppointmentNew.q = data
  },
  setService(state, data){
    state.createAppointmentNew.service = data
  },
  setAge(state, age){
    state.createAppointmentNew.age = age
  },
}