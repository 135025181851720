<template>
  <div>
    <ProgressBar :progress="2"/>
    <CalendarLine @change-date="getData" :getAllowedDates="getAllowedDates" />
    <UsersSpecialsList 
      :usersShowHandler="usersShowHandler"
      :setIdSpecial="setIdSpecial"
    />
    <div v-if="usersShow && !loading">
      <UserCard v-for="user in activeUsers" :key="user.id" :user="user" :showIntervals="true" :link="'/user'"/>
    </div>
    <Skeleton v-if="loading"/>
    <div class="notify" v-else-if="emptyUsers && usersShow">
      <i class="fas fa-exclamation-circle"></i>
      <div>на выбранную дату нет свободных врачей</div>
    </div>
  </div>
</template>

<script>

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import CalendarLine from '../../components/appointment/CalendarLine.vue'
import UserCard from '../../components/appointment/UserCard.vue'
import UsersSpecialsList from '../../components/appointment/UsersSpecialsList.vue'
import Skeleton from '../../components/appointment/Skeleton.vue'

import { mapGetters } from 'vuex'
import router from '../../router'
import moment from 'moment'

export default {
  name: "Users",
  data(){
    return {
      // отображение списка врачей под текущую дату (если не введены данные в строку поиска)
      usersShow: true,
      // отображение загрузки при получении данных
      loading: false,
      // фильтр поиска врачей по специальности
      idSpecial: ''
    }
  },
  beforeMount(){
    // перенаправление если не выбран возраст клиента
    if (!this.age) router.push('/appointment-new')
    // получение пользователей для текущей даты
    this.$store.commit('setService', null)
    localStorage.service = null
    this.getData()
  },
  components: {
    ProgressBar,
    CalendarLine,
    UserCard,
    UsersSpecialsList,
    Skeleton,
  },
  methods: {
    // получение пользователей для текущей даты
    async getData(){
      // активирует загрузку
      this.loading = true
      // получение данных
      await this.$store.dispatch('getUsers', this.idSpecial)
      // скрыть загрузку
      .then(() => {this.loading = false})
    },
    // отображение/скрытие списка пользователей для текущей даты
    usersShowHandler(show){
      this.usersShow = show
    },
    setIdSpecial(id){
      this.idSpecial = id
    },
    // функция определения неактивных дат в дейтпикере
    getAllowedDates (val) {
      const date = moment().format(moment.HTML5_FMT.DATE)
      if (moment(date).isAfter(val)) {
        return false
      } else {
        return true
      }
    },
  },
  computed: {
    ...mapGetters(['activeUsers', 'date', 'age']),
    // проверка, есть ли пользователи для текущей даты
    emptyUsers(){
      for (let key in this.activeUsers) {
        return false
      }
      return true
    },
  },
  watch: {
    idSpecial: function(){
      this.getData()
    }
  }

}
</script>

<style scoped>
  .notify{
    color: #ccc;
    text-align: center;
  }
</style>