<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="toBack" >
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
          </div>
        </div>
      </v-container>
    </div>
    <v-container>
      <v-row v-if="!loaded">
        <v-col class="p-0">
          <div>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="loaded">
        <v-col cols=12 class="section-title">
          <h5>Текущие записи к врачу</h5>
        </v-col>
        <v-col cols="12" v-if="isEmpty" class="pt-0">
          <v-alert prominent text>
            На данный момент у вас нет активных записей
          </v-alert>
        </v-col>
      </v-row>     
      <v-row >
        <v-col class="pt-0" cols="12" :key="appointment.id" v-for="appointment in appointments">
          <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content class="pt-3 pb-2">
                  <div style="width: 100%">
                    <div class="d-flex justify-space-between mb-1">
                      <div>
                        <v-chip class="mb-1 mr-2" color="grey lighten-2" text-color="grey" small v-if="!appointment.onlineVideo && !appointment.onlineChat">
                          <v-avatar left class="mr-1">
                            <v-icon small>far fa-calendar-alt</v-icon>
                          </v-avatar>Запись на прием
                        </v-chip>
                        <v-chip class="mb-1 mr-2" color="grey lighten-2" text-color="grey" small v-if="appointment.onlineVideo">
                          <v-avatar left class="mr-1">
                            <v-icon small>fas fa-video</v-icon>
                          </v-avatar>Видео с врачом
                        </v-chip>
                        <v-chip class="mb-1 mr-2" color="grey lighten-2" text-color="grey" small v-if="appointment.onlineChat">
                          <v-avatar left class="mr-1">
                            <v-icon small>fas fa-comment</v-icon>
                          </v-avatar>Чат с врачом
                        </v-chip>
                        <v-chip v-if="appointment.idStatus == 1" class="mb-1 mr-2" color="light-green lighten-1" text-color="white" small >
                        <v-avatar left class="mr-1">
                          <v-icon small>fas fa-check-circle</v-icon>
                        </v-avatar>Оплачено
                      </v-chip>
                      <v-chip v-if="appointment.idStatus === 0 && STATIC.appUseTelemedicine" class="mb-1 mr-2" color="yellow darken-2" text-color="white" small >
                        <v-avatar left class="mr-1">
                          <v-icon small>fas fa-clock</v-icon>
                        </v-avatar>Ожидает оплаты
                      </v-chip>

                      </div>
                      <div>
                        <v-btn icon class="px-0" title="Отменить запись" @click="cancelAppointment(appointment.id)" small text color="grey" rounded v-if="getDate() < appointment.dateFrom && (!appointment.idPayment || appointment.idStatus == 0)">
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div>
                      <v-list-item-title class="mb-2">
                        <small>
                         <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>{{ appointmentFormatDate(appointment.dateFrom) }}
                       
                        <span v-if="appointment.filialAddress">
                          <br><v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-map-marker-alt</v-icon>{{appointment.filialAddress}}
                        </span>

                        <span v-if="!appointment.filialAddress">
                          <br><v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-map-marker-alt</v-icon>{{STATIC.addressActual}}
                        </span>

                        </small>
                      </v-list-item-title>
            
                    <div class="mb-1">
                      <small><b>Пациент:</b> {{ appointment.customerSurname }} {{ appointment.customerName }} {{ appointment.customerMiddleName }}</small>
                    </div>
                    <div class="mb-1">
                      <small><b>Специалист:</b> {{ appointment.surname }} {{ appointment.name }} {{ appointment.middleName }} </small>
                    </div>
                    <div class="mb-1" v-if="appointment.nameService">
                      <small>
                        <b>Услуга:</b> {{appointment.nameService}}&nbsp;
                      </small>
                    </div>
               
                    <div class="" v-if="appointment.requirePrepaymentInApp">
                      <v-alert prominent text class="mb-1"> 
                        Для выполнения услуги необходимо произвести оплату
                      </v-alert>
                    </div>

                    <div class="mt-1" v-if="appointment.messageToCustomerOnMakingAppointment">
                      <small>
                        <v-icon x-small>fas fa-info-circle</v-icon> {{appointment.messageToCustomerOnMakingAppointment}}
                      </small>
                    </div>
                    
                  </div> 
                </div>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions class="pl-3 pb-3" v-if="STATIC.appUseTelemedicine" >
                <v-btn small rounded dark color="primary" v-if="appointment.idStatus === 0 && appointment.idPayment" @click="toPay(appointment.idPayment)">
                  Оплатить
                </v-btn>
                <v-btn @click="goToChat" small rounded dark color="primary" v-if="(appointment.onlineVideo || appointment.onlineChat) && appointment.idStatus == 1">Перейти к онлайн-консультации</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="cancelAppointmentDialog" persistent max-width="400">
      <v-card>
        <v-card-title>Отмена записи</v-card-title>
        <v-card-text>
          <p>Вы действительно хотите отменить запись?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" text @click="cancelAppointmentDialog = false">Нет</v-btn>
          <v-btn rounded color="primary" text :loading="cancelAppointmentLoading" @click="confirmCancelAppointment">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelAppointmentErrorDialog" max-width="400">
      <v-card>
        <v-card-title>Отмена записи</v-card-title>
        <v-card-text>
          <p>Не удалось отменить запись. {{this.cancelAppointmentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded text color="primary" dark @click="cancelAppointmentErrorDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorPaymentDialog" max-width="400">
      <v-card>
        <v-card-title>Оплата услуги</v-card-title>
        <v-card-text>
          <p>Не удалось выполнить оплату услуги. {{this.paymentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="hidePaymentError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { AppointmentsApiService} from '@/services/api'

export default {
    name: 'Appointments',
    data: function() {
      return {
        updateInterval: null,
        appointments: [], // Записи
        loaded: false,
        title: 'Мои записи',
        cancelAppointmentDialog: false, // Отображение диалога отмены
        cancelAppointmentId: null, // id записи для отмены
        cancelAppointmentLoading: false, // Анимация загрузки удаления записи
        cancelAppointmentErrorText: null,
        cancelAppointmentErrorDialog: false,
        paymentErrorText: '',
        errorPaymentDialog: false
      }
    },
    async mounted() {
      await this.getAppointments()
      this.updateInterval = setInterval(() => {
        this.getAppointments()
      }, 20000)
    },
    beforeDestroy(updateInterval) {
      clearInterval(this.updateInterval);
    },
    methods: {
        // Скрыть ошибку о неудачной оплаты счета
        hidePaymentError(){
          this.errorPaymentDialog = false;
        },
        // Перейти к оплате талона
        toPay(idPayment){
          // Подготавливаем адрес возврата после оплаты
          let paymentStatusUrl = '';
          if (window.indexPlatform == 'ios'){
            paymentStatusUrl = window.config.url + '/cordova-ios.html'
          }
          if (window.indexPlatform == 'android'){
            paymentStatusUrl = window.config.url + '/cordova-android.html'
          }
          if (window.indexPlatform == 'browser'){
            paymentStatusUrl = window.config.url + '/index.html'
          }
          // Создаем заказ
          this.$http.post(window.config.apiUrl + '/payments/create-payment-order', {
            idPayment: idPayment,
            paymentStatusUrl
          })
          .then((response) => {
            // Если нет ошибок
            if (response.data.result.status == 'success'){
              console.log(response.data.result.acquiring);
                // Если форма оплаты получена
                if (response.data.result.acquiring.formUrl){
                  // Перенаправляем на форму оплаты
                  window.location = response.data.result.acquiring.formUrl;
                } else {
                  this.paymentErrorText = 'Не удалось перейти на страницу оплаты';
                  this.errorPaymentDialog = true;
                }
            } else {
              this.paymentErrorText = response.data.result.error;
              this.errorPaymentDialog = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.paymentErrorText = '';
            this.errorPaymentDialog = true;
          });
        },
        // Перейти к чату
        goToChat(){
          this.$router.push('/chat');
        },
        getDate(){
          return this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        // Вернутся назад
        toBack: function(){
            this.$router.push('/');
        },
        // Отменить запись
        cancelAppointment(idAppointment){
            this.cancelAppointmentLoading = false;
            this.cancelAppointmentDialog = true;
            this.cancelAppointmentId = idAppointment;
        },
        // Вернуть отформатированную дату записи
        appointmentFormatDate(dateFrom){
            return this.$moment(dateFrom).format("LL, dddd, LT");
        },
        // Подтверждение удаление записи
        confirmCancelAppointment(){
            this.cancelAppointmentLoading = true;
            this.$http.post(window.config.apiUrl + '/appointment/cancel-appointment', {
                idAppointment: this.cancelAppointmentId,
                idCustomer: this.$store.getters.activeUser
            }).then((response) => {
                if (response.data.status == 'success'){
                    this.cancelAppointmentDialog = false;
                    this.appointments = this.appointments.filter(appointment => appointment.id != this.cancelAppointmentId);
                } else {
                    this.cancelAppointmentDialog = false;
                    this.cancelAppointmentErrorDialog = true;
                    this.cancelAppointmentErrorText = response.data.errorText
                }
            })
            .catch((error) => {
                console.log(error);
                this.cancelAppointmentDialog = false;
                this.cancelAppointmentErrorDialog = true;
                this.cancelAppointmentErrorText = error.statusText;
            });
        },
        // Получить список записей
        async getAppointments() {
            try {
                let appointments = await AppointmentsApiService.getAppointments()
                this.appointments = appointments
            } catch (err) {
                this.appointments = null
            } finally {
                this.loaded = true
            }
        },
    },
    computed: {
        ...mapGetters([
          'STATIC'
        ]),
        isEmpty() {
             if (this.appointments){
               return this.appointments.length == 0 ? true : false
            } else {
              return true;
            }
        },
        
    }
}

</script>

<style scoped>

.main-container{
    height: calc(100vh - 300px);
}

@media screen and  (max-width: 1200px){

    .main-container{
        height: calc(100vh - 200px);
    }

}

</style>