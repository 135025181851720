import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login.vue'
import Account from '@/views/layouts/Account.vue'
import Home from '@/views/Home.vue'
import SelectUser from '@/views/SelectUser.vue'
import Appointments from '@/views/Appointments.vue'
import History from '@/views/History.vue'
import PaymentProcess from '@/views/PaymentProcess.vue'
import Information from '@/views/Information.vue'
import Recovery from '@/views/Recovery.vue'
import Register from '@/views/Register.vue'
import NotAvailable from '@/views/NotAvailable.vue'
import Analyses from '@/views/Analyses.vue'
import Contact from '@/views/Contact.vue'
import Chat from '@/components/chat/Chat.vue'
import Peer from '@/views/Peer.vue'
import Map from '@/views/Map.vue'
import store from '@/store'
import Appointment from '@/views/Appointment.vue'
import CreateSelectSpecial from '@/components/createAppointment/SelectSpecial.vue'
import CreateSelectUser from '@/components/createAppointment/SelectUser.vue'
import CreateSelectDate from '@/components/createAppointment/SelectDate.vue'
import CreateConfirm from '@/components/createAppointment/Confirm.vue'
import ViewAppointment from '@/views/ViewAppointment.vue'
import SuccessPayment from '@/views/SuccessPayment.vue'
import ErrorPayment from '@/views/ErrorPayment.vue'
import Review from '@/views/Review.vue'

import Clients from '@/views/appointment/Clients.vue'
import Directions from '@/views/appointment/Directions.vue'
import Users from '@/views/appointment/Users.vue'
import UsersByService from '@/views/appointment/UsersByService.vue'
import UsersByServiceUnprotected from '@/views/appointment/UsersByServiceUnprotected.vue'
import User from '@/views/appointment/User.vue'
import UserUnprotected from '@/views/appointment/UserUnprotected.vue'
import Services from '@/views/appointment/Services.vue'
import ApplyAppointment from '@/views/appointment/ApplyAppointment.vue'
import ApplyAppointmentUnprotected from '@/views/appointment/ApplyAppointmentUnprotected.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
  { 
    path: '/login', 
    component: Login 
  },
  { 
    path: '/peer', 
    name: 'Peer', 
    component: Peer, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: Account,
    children: [
      { 
        path: '', 
        name: 'Home', 
        component: Home, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: 'index.html', 
        redirect: '/'
      },
      { 
        path: '/select-user', 
        name: 'SelectUser', 
        component: SelectUser, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/appointments', 
        name: 'Appointments', 
        component: Appointments, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/history', 
        name: 'History', 
        component: History, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/payment-process', 
        name: 'PaymentProcess', 
        component: PaymentProcess, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/analyses', 
        name: 'Analyses', 
        component: Analyses, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/contact', 
        name: 'Contact', 
        component: Contact, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/map', 
        name: 'Map', 
        component: Map, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/chat', 
        name: 'Chat', 
        component: Chat, 
        meta: { 
          requiresAuth: true
        }
      },
      { 
        path: '/information', 
        name: 'Information', 
        component: Information, 
        meta: { 
          requiresAuth: true
        }
      },
      // { 
      //   path: '/payment', 
      //   name: 'Payment', 
      //   component: Payment, 
      //   meta: { 
      //     requiresAuth: true
      //   }
      // },
      {
        // Страница успешной оплаты
        path: '/success-payment',
        name: 'SuccessPayment',
        component: SuccessPayment,
        meta: { 
          requiresAuth: true
        }
      },
      {
        // Страница ошибки оплаты
        path: '/error-payment',
        name: 'ErrorPayment',
        component: ErrorPayment,
        meta: { 
          requiresAuth: true
        }
      },
      {
          // Страница оплаты
          path: '/view-appointment',
          name: 'ViewAppointment',
          component: ViewAppointment,
          meta: { 
              requiresAuth: true
          }
      },
      {
        // Страница создания записи
        path: '/appointment-old/',
        name: 'Appointment',
        component: Appointment,
        meta: { 
            requiresAuth: true
        },
        children: [
          { 
            path: '', 
            name: 'CreateSelectSpecial', 
            component: CreateSelectSpecial, 
            meta: { 
                requiresAuth: true
            }
          },
          { 
              path: 'select-user', 
              name: 'CreateSelectUser', 
              component: CreateSelectUser, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: 'select-date', 
              name: 'CreateSelectDate', 
              component: CreateSelectDate, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: 'confirm', 
              name: 'CreateConfirm', 
              component: CreateConfirm, 
              meta: { 
                  requiresAuth: true
              }
          },
          ]
      },
      {
        // Страница создания записи новая
        path: '/appointment/',
        name: 'Appointment',
        component: Appointment,
        meta: { 
            requiresAuth: true
        },
        children: [
          { 
            path: '', 
            name: 'ClientSelect', 
            component: Clients, 
            meta: { 
                requiresAuth: true
            }
          },
          { 
            path: '/direction', 
            name: 'DirectionSelect', 
            component: Directions, 
            meta: { 
                requiresAuth: true
            }
          },
          { 
              path: '/users', 
              name: 'UserSelect', 
              component: Users, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: '/services', 
              name: 'ServiceSelect', 
              component: Services, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: '/user', 
              name: 'UserInfo', 
              component: User, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: '/users-by-service', 
              name: 'UsersByService', 
              component: UsersByService, 
              meta: { 
                  requiresAuth: true
              }
          },
          { 
              path: '/apply-appointment', 
              name: 'ApplyAppointment', 
              component: ApplyAppointment, 
              meta: { 
                  requiresAuth: true
              }
          },
          ]
      },
    ]
  },
  {
    // Страница создания записи новая
    path: '/new-appointment',
    name: 'Appointment',
    component: Appointment,
    children: [
      { 
        path: '', 
        name: 'UsersByServiceUnprotected', 
        component: UsersByServiceUnprotected, 
      },
      { 
        path: '/new-appointment/user', 
        name: 'UserInfo', 
        component: UserUnprotected, 
      },
      { 
        path: '/new-appointment/apply-appointment', 
        name: 'ApplyAppointmentUnprotected', 
        component: ApplyAppointmentUnprotected,
      },
      ]
  },
  {
    // Страница отзыва
    path: '/review',
    name: 'Review',
    component: Review
  },
  {
    // Приложение не доступно
    path: '/not-available',
    name: 'NotAvailable',
    component: NotAvailable
  },
  {
    // Восстановление доступа
    path: '/recovery',
    name: 'Recovery',
    component: Recovery
  },
  {
    // Регистрация
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '*',
    name: '404',
    redirect: '/'
  },
]});

// Дублирование кода
// router.beforeEach((to, from, next) => {
//     if (!store.getters.activeUser && localStorage.getItem('idUser')){
//         store.dispatch('selectUser', localStorage.getItem('idUser'));
//     }
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (localStorage.getItem('token')) {
//             next()
//             return
//         }
//         next('/login') 
//     } else {
//         next() 
//     }
// })💩

export default router;
