<template>
  <div>
    <div class="header-mobile">
      <v-container>
          <div class="top-toolbar">
            <div class="navigate-btn" >
              <v-btn icon @click="toBack">
                <v-icon>fas fa-angle-left</v-icon>
              </v-btn>
            </div>
            <div class="page-title">
              {{title}}
            </div>
            <div class="second-btn">
            </div>
          </div>
        </v-container>
    </div>
    <div>
      <v-container>
        <v-row v-if="loaded">
          <v-col cols=12 class="section-title">
            <h5>Результаты исследований</h5>
          </v-col>
          <v-col cols="12" v-if="isEmpty" class="pt-0">   
            <v-alert prominent text>
              На данный момент готовые результаты исследований отсутствуют
            </v-alert>
          </v-col>
          <v-col class="pt-0" cols="12" :key="analysis.id" v-for="analysis in analyses">
            <v-card outlined>
                <v-list-item three-line>
                    <v-list-item-content class="pb-0">
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="overline">              
                                  <v-chip x-small>
                                    {{ analysis.specialName || 'Исследования' }}
                                  </v-chip>
                                </div>
                                <v-list-item-title class=" mb-1">
                                  <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>{{ analysisFormatDate(analysis.measurementDate) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-user-md</v-icon>{{ analysis.surname }} {{ analysis.name }} {{ analysis.middleName }}
                                </v-list-item-subtitle>
                            </div>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <v-card-actions class="pl-3 pb-3" >
                    <v-btn class="text-decoration-none" 
                        :disabled="!analysis.id" 
                        small 
                        text
                        link
                        :to="'/view-appointment?idExamination=' + analysis.id"
                        color="primary" 
                        :dark="!!(analysis.id)" 
                    >Просмотреть</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!loaded">
          <v-col class="p-0">
            <div >
              <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

import {AnalysesApiService} from '@/services/api'

export default {
  name: 'Analyzes',
  data: function() {
    return {
      analyses: [],
      loaded: false,
      title: 'Результаты исследований',
    }
  },
  methods: {
    toBack() {
      this.$router.push('/')
    },
    // Вернуть отформатированную дату осмпотра
    analysisFormatDate(dateFrom){
        return this.$moment(dateFrom).format("LL, dddd, LT");
    },
  },
  async mounted() {
    try {
      let analyses = await AnalysesApiService.getAnalyses()
      this.analyses = analyses
    } catch (err) {
      this.analyses = null
    } finally {
      this.loaded = true
    }
    
  },
  computed: {
    isEmpty() {
      if (this.analyses){
        return this.analyses.length == 0 ? true : false
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>

@media screen and  (max-width: 1200px){

}

</style>
