var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[(_vm.$mq === 'lg')?_c('v-col',{staticClass:"progress-bar-line",attrs:{"md":"10","offset-md":"1","sm":"10","offset-sm":"1","xs":"12"}},[(this.getUser.representatives.length)?_c('div',{key:"0",staticClass:"progress-item",class:[
          _vm.progress === 0 ? 'active' : '', 
          _vm.progress > 0 ? 'completed' : ''
          ]},[(_vm.progress>0)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),_vm._v(" укажите клиента ")]):_vm._e(),_c('div',{key:"1",staticClass:"progress-item",class:[
          _vm.progress === 1 ? 'active' : '', 
          _vm.progress > 1 ? 'completed' : ''
          ]},[(_vm.progress>1)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),_vm._v(" выберите услугу ")]),_c('div',{key:"2",staticClass:"progress-item",class:[
          _vm.progress === 2 ? 'active' : '', 
          _vm.progress > 2 ? 'completed' : ''
          ]},[(_vm.progress>2)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),_vm._v(" выберите врача и время ")]),_c('div',{key:"3",staticClass:"progress-item",class:[
          _vm.progress === 3 ? 'active' : '', 
          _vm.progress > 3 ? 'completed' : ''
          ]},[(_vm.progress>3)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),_vm._v(" подтвердите запись ")])]):_vm._e(),(_vm.$mq!=='lg')?_c('v-col',{staticClass:"progress-bar-line",attrs:{"xs":"12"}},[_c('div',{staticClass:"progress-bar-xs active"},[(_vm.$mq === 'sm')?_c('span',[_vm._v("Запись")]):_vm._e(),(_vm.$mq !== 'sm')?_c('span',[_vm._v("Запись на прием")]):_vm._e()]),_c('div',{staticClass:"progress-bar-xs",class:[_vm.progress >= 1 ? 'active' : '']}),_c('div',{staticClass:"progress-bar-xs",class:[_vm.progress >= 2 ? 'active' : '']}),_c('div',{staticClass:"progress-bar-xs",class:[_vm.progress >= 3 ? 'active' : ''],staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.progress+1)+"/4 ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }